import React from 'react';
import Navbar from '../components/Navbar';
import {Hero} from '../components/contactuscomponents/Hero';
import {FooterForm} from '../components/FooterForm';
import {Footer} from '../components/Footer';
import {OurWork} from '../components/ourworkcomponents/OurWork';
export const Portfolio = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Hero pageName="" title="PROJECTS BY FIT 
        DESIGN" desc="" hiddenDesc="hidden" />
      <OurWork />
      <FooterForm />
      <Footer />
    </React.Fragment>
  );
};

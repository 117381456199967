import React from 'react';
import ceoImage from '../../assets/images/maqsood-ahmed.webp';
import ceoImage1 from '../../assets/images/mobile-sm.webp';
import { PopupWidget } from 'react-calendly';

export const BookMeeting = () => {
  return (
    <React.Fragment>
      <section className="mainMarginTop maindPadding">
        <div className="flex justify-end book-meeting-flex relative">
          <div className=" book-meeting-home-col-1 ">
            <div className="bg-black book-meeting-home lg:px-8 px-4 lg:py-12 py-7 mt-20 ">
              <h1 className="text-white book-meeting-title lg:text-4xl sm:text-2xl text-2xl md:text-3xl  font-black">
                Bonjour to our Digital Marketing Agency!
              </h1>
              <p className="text-white leading-6 pt-6 pb-6">
                The community knows us for our commitment, authenticity, and effectiveness. We are a team of experienced professionals assisting businesses to reach their target audience and secure marketing goals. Our team has a proven record of accomplishment in helping our clients increase website traffic and stimulate sales. We use data-driven practices to ensure that our campaigns are effective and deliver substantial results.
              </p>
              {/* <p className="text-white leading-6 pt-6 ">
              We are a one-stop agency for E-commerce,  web/mobile applications, graphic designing, digital marketing and social media management. We take your business, create its identity from scratch, and build the ideal brand for your target market.

              </p> */}
              <div className="pt-6">
                <button className=" bg-16A085 text-white uppercase w-full relative font-semibold  px-7  text-center">
                  <PopupWidget
                    url="https://calendly.com/maqsoodfcc/30min?month=2023-03"
                    rootElement={document.getElementById('root')}
                    text="Let’s Talk"
                    textColor="#ffffff"
                    color="#16A085"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="book-meeting-home-col-2 webHomwMeeting relative mt-0">
            <img src={ceoImage} alt="not found" className="w-full" />
          </div>
          <div className="book-meeting-home-col-2 smHomwMeeting lg:hidden md:hidden sm:hidden hidden relative mt-0">
            <img src={ceoImage1} alt="not found" className="w-full" />
          </div>
        </div>

      </section>

    </React.Fragment>
  );
};

import React from 'react';
import Lightroom from 'react-lightbox-gallery';
import g1 from '../../assets/images/our-work/uiux/1.webp';
import g2 from '../../assets/images/our-work/uiux/2.webp';
import g3 from '../../assets/images/our-work/uiux/3.webp';
import g4 from '../../assets/images/our-work/uiux/4.webp';
import g5 from '../../assets/images/our-work/uiux/5.webp';
export const Uiux = () => {
  var images = [
    {
      src: g1,
      desc: '',
      sub: '',
    },
    {
      src: g2,
      desc: ' ',
      sub: '',
    },
    {
      src: g3,
      desc: ' ',
      sub: '',
    },
    {
      src: g4,
      desc: ' ',
      sub: '',
    },
    {
      src: g5,
      desc: ' ',
      sub: '',
    },
  ];

  var settings = {
    columnCount: {
      default: 3,
      mobile: 2,
      tab: 4,
    },
    mode: 'dark',
  };
  return (
    <React.Fragment>
      <section className=" bg-center bg-no-repeat  bg-cover  pb-12">
        {/* <div className="text-4xl portfolioImageInner sticky inset-x-0 top-0 left-0   bold-font  text-center m-auto text-center w-full">
          Portfolio
        </div> */}
        <div className="relative">
          <div className="flex uiux">
            <Lightroom images={images} settings={settings} />
          </div>
        </div>
      </section>

    </React.Fragment>
  );
};

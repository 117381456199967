import React from 'react';
import { Link } from 'react-router-dom';
import watsappImage from '../assets/images/watsapp.svg';
export const Footer = () => {
  const phoneNumber = '19193219995'; // replace with your phone number

  function handleClick() {
    window.location.href = `https://wa.me/${phoneNumber}`;
  }
  return (
    <React.Fragment>

      <section className=" maindPadding  bg-black pb-12">
        <div className="bg-16A085 flex flex-wrap lg:justify-between sm:justify-center justify-center items-center p-7 mb-3 relative footerTop">
          <div className="lg:text-2xl text-xl font-black text-white bold-font lg:text-start sm:text-center text-center">
            One-Step Solution bringing Data & Strategy Boosted in No time.
          </div>
          <div onClick={handleClick} className="flex items-center lg:mt-0 sm:mt-5 mt-5 cursor-pointer">
            <div><img src={watsappImage} alt="..." /></div>
            <div className="lg:text-2xl text-xl font-black text-white bold-font pl-4">
              +19193219995
            </div>
          </div>
        </div>
        <footer className="flex flex-wrap justify-between pb-9">
          <div className="footer-col">
            <div className="text-847F7F">Services</div>
            <Link to="/services/seo" className="text-white block pt-4">Search Engin Optimization</Link>
            <Link to='/services/social' className="text-white block pt-2">Social Media Marketing</Link>
            <Link to='/services/ppcmanagement' className="text-white block pt-2">PPC Management</Link>
            <Link to='/services/webdesigndevelopment' className="text-white block pt-2">
              Website Design & Development
            </Link>
            <Link to='/services/leadgeneration' className="text-white block pt-2">Lead Generation</Link>
          </div>
          <div className="footer-col">
            <div className="text-847F7F">Learn</div>
            <Link to="/about" className="text-white block pt-4">About</Link>
            <a href="##" className="text-white block pt-2">Culture</a>
            <a href="/clients" className="text-white block pt-2">Testimonials</a>
            <a href="##" className="text-white block pt-2">
              FAQs
            </a>
            <a href="##" className="text-white block pt-2">Blog</a>
          </div>
          <div className="footer-col">
            <div className="text-847F7F">Explore</div>
            <a href="/" className="text-white block pt-4">Home</a>
            <a href="/portfolio" className="text-white block pt-2">Work</a>
            <a href="/services" className="text-white block pt-2">Services</a>
            <a href="##" className="text-white block pt-2">
              Careers
            </a>
            <a href="/contact-us" className="text-white block pt-2">Contact</a>
          </div>
          <div className="footer-col">
            <div className="text-847F7F">KNOWLEDGE BASE</div>
            <a href="##" className="text-white block pt-4">Internet Marketing</a>
            <a href="##" className="text-white block pt-2">Content Marketing</a>
            <a href="##" className="text-white block pt-2">Social Media</a>
            <a href="##" className="text-white block pt-2">
              Web Design
            </a>
            <a href="##" className="text-white block pt-2">SEO</a>
          </div>
          <div className="footer-col">
            <div className="text-847F7F">Headquarter:</div>
            <div className="text-white block pt-4">
              6210 Plantation Center Dr
              Suite 105, Raleigh, NC, 27616 USA

            </div>
            <div className="text-847F7F pt-8">Other Offices</div>
            <div className="text-white block pt-2">
              5th Floor Mezan Executive Tower, Liaquat Road, Faisalabad Pakistan
            </div>
          </div>
        </footer>

        <div className="flex pt-9 lg:justify-end justify-between sm:justify-between">
          <a href="##" className="text-white block">Privacy Policy </a>
          <a href="##" className="text-white block pl-7">Terms of Use </a>
          {/* <a href="##" className="text-white block"> Press Kit</a> */}
        </div>
      </section>
    </React.Fragment>
  );
};

import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/homecomponents/Hero";
import { BookMeeting } from "../components/homecomponents/BookMeeting";
import { Lead } from "../components/homecomponents/Lead";
import { ClientLogos } from "../components/homecomponents/ClientLogos";
import { GetInspired } from "../components/homecomponents/GetInspired";
import { OurServices } from "../components/homecomponents/OurServices";
import {AboutMe} from '../components/homecomponents/AboutMe'
import { Reviews } from "../components/homecomponents/Reviews";
import { Portfolio } from "../components/Portfolio";
import { FooterForm } from "../components/FooterForm";
import { Footer } from "../components/Footer";
import { useState } from "react";
import { useLocation } from 'react-router-dom';
export const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    // Simulate a 5-second loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, [location]);
  return (
    <React.Fragment>
      <>
        {isLoading ? (
          <div className="loader_">
            <video autoPlay muted className=" w-100 video-1 ">
              <source
                src={require("../assets/images/loader.mp4")}
                type="video/mp4"
              />
            </video>
          </div>
        ) : (
          <>
            <Navbar />
            <section className="relative banner-home-bg video-main-banner">
              <div className="absolute  flex items-center justify-center items-center h-full w-full">
                <Hero />
              </div>
              <div className="ewd-offic-video ">
                <video autoPlay muted loop className=" w-100 video-1 ">
                  <source
                    src={require("../assets/video/ewd-video.mp4")}
                    type="video/mp4"
                  />
                </video>
              </div>
            </section>
            <BookMeeting />
            <Lead />
            <ClientLogos
              title="Our Clients"
              desc="Our data-driven approach excites us to help our clients thrive by evergreen support"
              bgColor="bg-1e1e1e"
            />
            <GetInspired desc="In today's fast-paced and competitive digital marketplace, it is more important than ever to have a robust digital presence. That is where we come in. " />
            <OurServices />
            <Reviews />
            <Portfolio />
            <AboutMe />
            <FooterForm desc="We believe in transforming generations through innovation and displaying the remarkable services. We would love to add engaging and refreshing spotlight at your brand." />
            <Footer />
          </>
        )}
      </>
    </React.Fragment>
  );
};

import React, { useState } from 'react';
import logo from '../assets/images/logo.webp';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import { PopupWidget } from 'react-calendly';
import { Clients } from '../pages/clients';
import { Careers } from '../pages/careers';
export const Navbar = ({ navClass }) => {
  const [backgroundColor, setbackgroundColor] = useState('bg-transparent');
  const [showDropDown, setshowDropDown] = useState('hidden');
  const [menu, setMenu] = useState('hidden');
  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
  });
  function scrollFunction() {
    document.body.scrollTop > 20 || document.documentElement.scrollTop > 20
      ? setbackgroundColor('bg-black')
      : setbackgroundColor('bg-transparent');
  }
  // var classNameAnchor = ';
  return (
    <React.Fragment>
      <nav
        className={`lg:px-12 ${navClass} md:px-3 sm:px-3 px-3 ${backgroundColor}   py-5   bg-dark  fixed w-full z-20 top-0 left-0 `}
      >
        <div className=" flex flex-wrap justify-between items-center">
          <Link to="/" className="flex items-center">
            <img src={logo} className="mr-3" alt="EWD Logo" />
          </Link>
          <div className="flex md:order-2 h-11">
            <div className="sm-hidden md:show">
              <button
                type="button"
                className=" bg-16A085 uppercase text-white h-full flex items-center font-semibold  px-7 py-2.5 text-center"
              >
                <PopupWidget
                  url="https://calendly.com/maqsoodfcc/30min?month=2023-03"
                  rootElement={document.getElementById('root')}
                  text="Let’s Talk"
                  textColor="#ffffff"
                  color="#16A085"
                />
              </button>
            </div>
            <button
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden  focus:outline-none   "
              aria-controls="navbar-sticky"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                onClick={() => {
                  menu === 'hidden' ? setMenu('show') : setMenu('hidden');
                }}
                className="w-6 h-6"
                aria-hidden="true"
                fill="#fff"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div
            className={`${menu} justify-between items-center w-full md:flex md:w-auto md:order-1 navDiv`}
            id="navbar-sticky"
          >
            <ul className="flex flex-col  px-0  navbarUl  rounded-lg  border-0 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium   md:dark:bg-gray-900">
              <li className="navbarUlLi">
                <NavLink
                  to="/about"
                  // className="
                  aria-current="page"
                  className={({ isActive }) =>
                    isActive
                      ? 'active block uppercase px-2 text-base font-medium  navbarAnchorActive'
                      : 'inactive block uppercase px-2 text-base font-medium text-white navbarAnchorActive'}
                >
                  About
                </NavLink>
              </li>

              <li className="navbarUlLi">
                <button
                  onMouseEnter={() => {
                    setshowDropDown('show');
                  }}
                  onMouseLeave={() => {
                    setshowDropDown('hidden');
                  }}
                  onClick={() => {
                    showDropDown === 'hidden'
                      ? setshowDropDown('show')
                      : setshowDropDown('hidden');
                  }}
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownNavbar"
                  className="flex justify-between items-center text-white text-base font-medium uppercase px-2 w-full  md:w-auto "
                >
                  Services
                  {' '}
                  {/* <svg
                    className="ml-1 w-4 h-4"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="#fff"
                    className="ml-1 "
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </button>
                {/* <!-- Dropdown menu --> */}
                <div
                  className="pt-4 absolute"
                  onMouseEnter={() => {
                    setshowDropDown('show');
                  }}
                  onMouseLeave={() => {
                    setshowDropDown('hidden');
                  }}
                >
                  <div
                    id="dropdownNavbar"
                    className={`${showDropDown} z-10   px-4  py-2 font-normal bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600`}
                  >
                    <ul
                      className="py-1 text-sm text-gray-700 dark:text-gray-400"
                      aria-labelledby="dropdownLargeButton"
                    >
                      <li>
                        <Link
                          to="/services/seo"
                          className="block uppercase px-2 text-sm font-medium"
                        >
                          Seo
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/ecommerce"
                          className="block uppercase px-2 pt-2 navbarInnerUl text-sm font-medium"
                        >
                          Ecommerce
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/shopify"
                          className="block uppercase px-2 pt-2 navbarInnerUl text-sm font-medium"
                        >
                          Shopify
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/branding"
                          className="block uppercase px-2 pt-2 navbarInnerUl text-sm font-medium"
                        >
                          Branding
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/social"
                          className="block uppercase px-2 pt-2 navbarInnerUl text-sm font-medium"
                        >
                          Social
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/webdesigndevelopment"
                          className="block uppercase px-2 pt-2 navbarInnerUl text-sm font-medium"
                        >
                          Website Design <br /> & Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/ppcmanagement"
                          className="block uppercase px-2 pt-2 navbarInnerUl text-sm font-medium"
                        >
                          PPC MANAGEMENT
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/leadgeneration"
                          className="block uppercase px-2 pt-2 navbarInnerUl text-sm font-medium"
                        >
                          LEAD GENERATION
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="navbarUlLi">
                <NavLink
                  to="/portfolio"
                  // className="block uppercase  px-2 text-white text-sm font-medium"
                  className={({ isActive }) =>
                    isActive
                      ? 'active block uppercase px-2 text-base font-medium  navbarAnchorActive'
                      : 'inactive block uppercase px-2 text-base font-medium text-white navbarAnchorActive'}
                >

                  Our Work
                </NavLink>
              </li>
              <li className="navbarUlLi">
                <NavLink
                  to="/clients"
                  // className="block uppercase px-2 text-white text-sm font-medium"
                  className="block uppercase px-2 text-base font-medium text-white navbarAnchorActive"
                >
                  Clients
                </NavLink>
              </li>
              {/* <li className="navbarUlLi">
                <a
                  href="#"
                  // className="block uppercase px-2 text-white font-medium text-sm"
                  className="block uppercase px-2 text-base font-medium text-white navbarAnchorActive"
                >
                  Insights
                </a>
              </li> */}
              <li className="navbarUlLi">
                <NavLink
                  to="/contact-us"
                  className="block uppercase px-2 text-base font-medium text-white navbarAnchorActive"
                >
                  Contact Us
                </NavLink>
              </li>
              <div className="navContact w-full px-5 pb-4">
                <button
                  type="button"
                  className=" bg-16A085 uppercase text-white nav-sm-btn font-semibold w-full  px-7 py-2.5 text-center"
                >
                  <PopupWidget
                    url="https://calendly.com/maqsoodfcc/30min?month=2023-03"
                    rootElement={document.getElementById('root')}
                    text="Let’s Talk"
                    textColor="#ffffff"
                    color="#16A085"
                  />
                </button>
              </div>
            </ul>
          </div>
        </div>
      </nav>

    </React.Fragment>
  );
};
export default Navbar;

import React from 'react';
import Lightroom from 'react-lightbox-gallery';
import g1 from '../../assets/images/our-work/web-app/1.webp';
import g2 from '../../assets/images/our-work/web-app/2.webp';
import g3 from '../../assets/images/our-work/web-app/3.webp';
import g4 from '../../assets/images/our-work/web-app/4.webp';
import g5 from '../../assets/images/our-work/web-app/5.webp';
import g6 from '../../assets/images/our-work/web-app/6.webp';
import g7 from '../../assets/images/our-work/web-app/7.webp';
import g8 from '../../assets/images/our-work/web-app/8.webp';
import g9 from '../../assets/images/our-work/web-app/9.webp';
import g10 from '../../assets/images/our-work/web-app/10.webp';
import g11 from '../../assets/images/our-work/web-app/11.webp';
import g12 from '../../assets/images/our-work/web-app/12.webp';

export const WebApp = () => {
  var images = [
    {
      src: g1,
      desc: '',
      sub: '',
    },
    {
      src: g2,
      desc: ' ',
      sub: '',
    },
    {
      src: g3,
      desc: ' ',
      sub: '',
    },
    {
      src: g4,
      desc: ' ',
      sub: '',
    },
    {
      src: g5,
      desc: ' ',
      sub: '',
    },
    {
      src: g6,
      desc: ' ',
      sub: '',
    },
    {
      src: g7,
      desc: ' ',
      sub: '',
    },
    {
      src: g8,
      desc: ' ',
      sub: '',
    },
    {
      src: g9,
      desc: ' ',
      sub: '',
    },
    {
      src: g10,
      desc: ' ',
      sub: '',
    },
    {
      src: g11,
      desc: ' ',
      sub: '',
    },
    {
      src: g12,
      desc: ' ',
      sub: '',
    },
  ];

  var settings = {
    columnCount: {
      default: 3,
      mobile: 2,
      tab: 4,
    },
    mode: 'dark',
  };
  return (
    <React.Fragment>
      <section className=" bg-center bg-no-repeat  bg-cover  pb-12">

        {/* <div className="text-4xl portfolioImageInner sticky inset-x-0 top-0 left-0   bold-font  text-center m-auto text-center w-full">
          Portfolio
        </div> */}

        <div className="relative flex flex-wrap">
          <Lightroom images={images} settings={settings} />
        </div>
      </section>

    </React.Fragment>
  );
};

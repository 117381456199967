import React from 'react';
import AnimationCount from 'react-count-animation';
import 'react-count-animation/dist/count.min.css';

export const Lead = () => {
  const settings = {
    start: 0,
    count: 6271747,
    duration: 5000,
    decimals: 0,
    useGroup: true,
    animation: 'up',
  };
  const settings2 = {
    start: 0,
    count: 4191222,
    duration: 5000,
    decimals: 0,
    useGroup: true,
    animation: 'up',
  };
  const settings3 = {
    start: 0,
    count: 11550403,
    duration: 5000,
    decimals: 0,
    useGroup: true,
    animation: 'up',
  };
  const settings4 = {
    start: 0,
    count: 579,
    duration: 5000,
    decimals: 0,
    useGroup: true,
    animation: 'up',
  };
  return (
    <React.Fragment>
      <section className="mainMarginTop maindPadding ">
        <section className="lgPaddingLeftRight counter justify-between flex flex-wrap">
          <div className="counter-child bg-f2f2f2 p-7">
            <h1 className="text-4xl font-black bold-font text-center">
              <AnimationCount {...settings} />
            </h1>
            <p className="opacity-70 text-center pt-3">Leads Generated</p>
          </div>
          <div className="counter-child bg-f2f2f2 p-7">
            <h1 className="text-4xl font-black bold-font text-center">
              <AnimationCount {...settings2} />
            </h1>
            <p className="opacity-70 text-center pt-3">Calls Generated</p>
          </div>
          <div className="counter-child bg-f2f2f2 p-7">
            <h1 className="text-4xl font-black bold-font text-center">
              <AnimationCount {...settings3} />
            </h1>
            <p className="opacity-70 text-center pt-3">
              eCommerce Transactions
            </p>
          </div>
          <div className="counter-child bg-f2f2f2 p-7">
            <h1 className="text-4xl font-black bold-font text-center">
              <AnimationCount {...settings4} />
            </h1>
            <p className="opacity-70 text-center pt-3">Client Testimonials</p>
          </div>
        </section>
      </section>
    </React.Fragment>
  );
};

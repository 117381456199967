import './index.css';
import React, { useEffect } from 'react';
import '../src/assets/css/mediaquery.css';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './assets/css/index.css';
import ScrollToTop from './components/ScrollToTop';
import { Contactus } from './pages/Contactus';
import { Services } from './pages/Services';
import { Seo } from './pages/Seo';
import { Ecommerce } from './pages/Ecommerce';
import { Shopify } from './pages/Shopify';
import { Branding } from './pages/Branding';
import { Social } from './pages/Social';
import { Webdesigndevelopment } from './pages/webdesigndevelopment';
import { PpcManagement } from './pages/PpcManagement';
import { LeadGeneration } from './pages/LeadGeneration';
import { Portfolio } from './pages/Portfolio';
import { Clients } from './pages/clients';
import { Careers } from './pages/careers';
import AOS from 'aos';
import NotFound from './pages/NotFound';
import 'aos/dist/aos.css';
function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  // useEffect (() => {
  //   import ('flowbite');
  // }, []);
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/seo" element={<Seo />} />
          <Route path="/services/ecommerce" element={<Ecommerce />} />
          <Route path="/services/shopify" element={<Shopify />} />
          <Route path="/services/branding" element={<Branding />} />
          <Route path="/services/social" element={<Social />} />
          <Route
            path="/services/webdesigndevelopment"
            element={<Webdesigndevelopment />}
          />
          <Route path="/services/ppcmanagement" element={<PpcManagement />} />
          <Route path="/services/leadgeneration" element={<LeadGeneration />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/careers" element={<Careers />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
export default App;

import React from 'react';
import ceoImage from '../../assets/images/AboutMe.svg';
import ceoImage1 from '../../assets/images/mobile-sm.webp';

import { PopupWidget } from 'react-calendly';

export const AboutMe = () => {
  return (
    <React.Fragment>
      <section className="mainMarginTop maindPadding">
        <h2 className="text-center text-4xl bold-font pb-12">Our Partner</h2>
        <div className="flex justify-end book-meeting-flex relative relativeMain">
          <div className=" book-meeting-home-col-1 ">
            <div className="bg-black book-meeting-home lg:px-8 px-4 lg:py-12 py-7 mt-20 ">
              <h1 className="text-white book-meeting-title lg:text-4xl sm:text-2xl text-2xl md:text-3xl  font-black">
                Digital Strategist</h1>
              <p className="text-white leading-6 pt-6">JM is an experienced Consultant, Coach and IT Strategist both
                in Corporate and Business World. JM have handled big development projects handling project teams which
                includes developers who JM have guided and coached as she’s once a developer herself.<br /><br />
                Additionally, JM is a well-sought Digital Expert, Motivational Speaker, Trainer, and Digital
                Transformation Coach on a mission to help trainers, speakers, coaches, and business leaders
                build authentic digital brands by providing Digital Coaching and Automation to prepare them to
                lead better &amp; have a positive impact to their own communities.</p>
              <div className="pt-12 mt-12"></div></div>
          </div><div className="book-meeting-home-col-2  relative mt-0">
            <img src={ceoImage} alt="not found" className="w-full" />
            <a href="https://www.facebook.com/jmdgenerous" target="_blank" className="relative relativeJM">
              <h4 className="text-center aboutJM">More About JM</h4></a></div></div></section>

    </React.Fragment>
  );
};

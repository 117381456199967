import React from 'react';
import shopify from '../../assets/images/shopify.svg';
import magento from '../../assets/images/magento.svg';
import woocommerce from '../../assets/images/woocommerce.svg';
import webflow from '../../assets/images/webflow.svg';
export const TrusedByStories = () => {
  const arr = [
    {id: 1, image: shopify},
    {id: 2, image: magento},
    {id: 3, image: woocommerce},
    {id: 4, image: webflow},
  ];
  return (
    <React.Fragment>

      <section className="mainPaddingTop maindPadding">
        <h1 className="text-4xl bold-font text-center   m-auto text-center w-full">
          Trusted by Top Stores
        </h1>
        <div className="counter pt-12 justify-between flex flex-wrap">
          {arr.map (e => {
            return (
              <div
                key={e.id}
                className="counter-child animation__image__div flex items-center justify-center  bg-f2f2f2 p-7"
              >
                <img
                  src={e.image}
                  alt="..."
                  className="m-auto animation__image"
                />
              </div>
            );
          })}

        </div>
      </section>
    </React.Fragment>
  );
};
